@import "variables.less";
@import "sprites.less";
@import "carousel.less";
@import "slider.less";

.front {

  .col-3 {
    width: 25%;
    float: left;
  }
  .col-4 {
    width: 33.33333333%;
    float: left;
  }
  .col-6 {
    width: 50%;
    float: left;
  }

  .panel-heading {
    text-align: center;
    background: none;
    border: none;
    color: @more-dark-blue-color;
    text-transform: uppercase;
    font-size: @title-font-size;
    margin-top: 20px;
    margin-bottom: 0;
  }

  .panel {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0;
    &.main-panel {
      border: none;
      box-shadow: none;
      height: 560px;

      background-image: linear-gradient(rgba(92, 107, 142, 0.7), rgba(255, 255, 255, 0.7)), url(/images/logo-t.jpg);
      .container {
        padding: 0;
      }
      .panel-body {
        padding: 0;
        position: relative;
        .top {
          height: 434px;

          .push-form {
            border: none;
            font-family: 'Questrial', sans-serif;
            color: #fff;
            background: #ffffffc4;
            cursor: pointer;
            padding: 10px 35px;
            letter-spacing: 1px;
            outline: none;
            font-size: 17px;
            font-weight: 100;
            border-bottom: 3px solid #0033a0c4;
            transition: 0.2s;
            -webkit-transition: 0.2s;
            -moz-transition: 0.2s;
            -o-transition: 0.2s;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            -o-border-radius: 4px;
            margin-top: 3em;
            margin: 15px auto !important;
            width: 215px;
            a {
              color: #fff !important;
            }
          }

          .main-text {
            position: absolute;
            top: 164px;
            left: 10px;
            color: #e10749;//#c3002f;
            font-size: 21px;
            width: 100%;
            .text-header {
              font-weight: 800;
              width: 940px;
              margin: 0 auto;
              text-align: center;
              background: #0033a0c4;
              padding: 7px 10px;
              color: #fff;
            }
            .text-subheading {
              color: #ffffff;
              width: 940px;
              margin: 0 auto;
              text-align: center;
              font-size: 18px;
              background: #0033a0c4;
              padding: 7px 10px;
            }
            p {
              margin: 0;
            }
          }
          .form-feedback {
            position: absolute;
            top: 54px;
            right: 14px;
          }
        }
        .bottom {
          height: 326px;

          h2 {
            font-size: 48px;
            color: #485c67;
            font-family: "Times New Roman";
            margin-top: 0;
            text-align: center;
            letter-spacing: 2.5px;
          }
          h3 {
            color: #485c67;
            font-size: 35px;
            font-family: "Times New Roman";
            text-align: center;
            letter-spacing: 2.5px;
          }
        }
      }
    }

    &.about-us-panel {
      text-align: center;
      background-color: #f5f5f5;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      border-top: 5px solid #c6c5c5;
      border-bottom: 5px solid #c6c5c5;

      h3 {
        text-transform: uppercase;
        color: #333;
        font-family: Open Sans;
        font-weight: bold;
        background: none;
      }

      .panel-body {
        padding-top: 40px;

        .row {
          margin-bottom: 44px
        }

        .circle {
          border: 3px solid #0033a0c4;
          width: 55px;
          height: 55px;
          background: #ededed;
          border-radius: 50px;
          display: inline-block;
          position: absolute;
          left: -55px;
          top: 10px;
          text-align: center;

          &:before {
            content: "";
            display: inline-block;
            margin-top: 6px;
            transform: scale(0.7, 0.7);
            -ms-transform: scale(0.7, 0.7);
            -webkit-transform: scale(0.7, 0.7);
          }

          &.team:before {
            .sprite(@icon-team);
          }
          &.quality:before {
            .sprite(@icon-quality);
          }
          &.prices:before {
            .sprite(@icon-price);
            width: 26px;
          }
          &.map:before {
            .sprite(@icon-map);
          }
          &.time:before {
            .sprite(@icon-time);
          }
          &.discount:before {
            .sprite(@icon-discount);
          }
          &.partner:before {
            .sprite(@icon-partner);
          }
        }

        h5 {
          font-family: Open Sans;
          font-size: 18px;
          line-height: 1.35;
          text-transform: uppercase;
          font-weight: bold;
          color: #565a64;
        }

        p {
          font-size: 18px;
          color: #565a64;
          font-family: Open Sans;
          & + p {
            font-size: 16px;
          }
        }
      }
    }

    &.services-panel {
      text-align: center;
      padding: 13px 20px;
      background: #ebebeb;
      margin-top: 56px !important;

      h3 {
        color: #c02020;
        display: inline-block;
        margin: 0 0 15px 0;
        padding: 0;
        color: #F26100;
        font-family: Helvetica Neue, Arial, sans-serif;
        font-size: 26px;
      }

      img {
        width: 250px;
        float: left;
        padding-right: 20px;
      }


      .panel-body {
        .services-list {
          list-style: none;
          text-align: left;
          li {
            list-style: none;
            font-size: 28px;
            color: #343739;
            background: #ffffff;
            border: 4px solid #c6c5c5;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            margin-bottom: 55px;
            position: relative;
            &:after {
              content: '';
              display: inline-block;
              background: #fff;
              width: 4px;
              height: 24px;
              position: absolute;
              top: 60px;
              left: 50%;
            }

            &.spec {
              margin-bottom: 141px;
              &:after {
                display: none;
              }
              ul {
                position: absolute;
                width: 100%;
                padding: 0;
                li {
                  display: inline-block;
                  margin-top: 55px;
                  padding: 0 50px;
                  &.left {
                    float: left;
                    margin-left: -75px
                  }
                  &.right {
                    float: right;
                    margin-right: -75px
                  }
                  &:after {
                    top: -40px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.benefits-panel {
      border-top: 5px solid #c6c5c5;
      border-bottom: 5px solid #c6c5c5;
      margin-top: 40px;

      .panel-body {
        padding: 25px;
        .benefits-list {
          list-style: none;
          li {
            list-style: none;
            font-size: 22px;
            color: #7c8b93;
            position: relative;
            margin-bottom: 40px;
            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              left: -95px;
            }

            &.work:before {
              .sprite(@icon-worker);
            }
            &.doc:before {
              .sprite(@icon-doc);
            }
            &.settings:before {
              .sprite(@icon-settings);
            }
            &.grafic:before {
              .sprite(@icon-grafic);
            }
            &.experience:before {
              .sprite(@icon-experience);
            }
          }
        }
      }
    }

    &.equipment-panel {
      text-align: center;
      h3 {
        color: #4e525d;
        padding: 7px 10px;
        display: inline-block;
        margin: 50px 0 42px 0;
        font-size: 42px;
      }
      .description {
        color: #4e525d;
        font-size: 30px;
      }

      .panel-body {
        text-align: left;
        .col {
          margin-bottom: 60px;
          display: table;
          &.mgtop {
            margin-top: 40px
          }
        }

        img {
          float: left;
          padding-right: 30px
        }
        p {
          font-size: 20px;
          color: #4e525d;
          vertical-align: middle;
          display: table-cell;
        }
      }
    }

    &.custom-panel {
      border-top: 5px solid #c6c5c5;
      border-bottom: 5px solid #c6c5c5;
      margin-top: 40px;
    }

    &.requisites-panel {
      background: #c6c5c5;
      margin: 50px auto !important;
      font-size: 20px;
      color: #565a64;

      .panel-body {
        margin: 24px 74px;
        padding: 0;
        padding-left: 40px;
        border-left: 1px solid #888888;
        .col {
          padding-left: 1px;
          border-right: 1px solid #888888;
          &.text-right {
            text-align: right;
          }
          &.text-center {
            text-align: center;
          }
          &.last {
            padding-right: 20px;
          }
        }
      }
    }


    &.emulators-panel {
      background: #E5E5E5;
      background-color: #f5f5f5;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      padding-bottom: 135px;
      .panel-body {
        .emulators-boxes {
          margin: 0 auto;
          text-align: center;

          .emulator {
            display: inline-block;
            margin-right: 35px;
            position: relative;
            .overlay {
              display: none;
              position: absolute;
              width: 100%;
              height: 100%;
              background: #7f8e9a;
              transition: all 500ms ease;
              top: 0;
              text-align: center;
              z-index: 100;
              cursor: pointer;
              background: rgba(127, 142, 154, 0.5);

              span {
                .sprite(@icon-zoom);
                height: 40px;
                width: 40px;
                display: block;
                margin: 0 auto;
                margin-top: 90px;
              }
            }
            &:hover {
              .overlay {
                display: block;
              }
              &:before {
                position: absolute;
                border: 6px solid;
                border-color: #cdb289 rgba(255, 255, 255, 0) #cdb289 #cdb289;
                width: 52px;
                height: 235px;
                content: '';
                display: block;
                top: -6px;
                left: -6px;
              }
              &:after {
                position: absolute;
                border: 6px solid;
                border-color: #cdb289 #cdb289 #cdb289 rgba(148, 140, 127, 0);
                width: 52px;
                height: 235px;
                content: '';
                display: block;
                top: -6px;
                right: -6px;
              }
            }
            &.last {
              margin-right: 0;
            }
          }
        }
      }
    }

    &.equipments-panel {
      .bg-img {
        background: url('/images/website-adblue-1.jpg');
        background-size: cover;
        min-height: 570px;
        padding: 0;
      }
      .layer-img {
        background: rgba(0, 0, 0, 0.6);
        padding: 8em 3em;
        .info {
          background: rgba(119, 119, 119, 0.3);
          padding: 4em 3em;
          border: 1px solid #888;
          img {
            position: absolute;
            top: 0;
            left: 0;
          }
          h3 {
             font-size: 30px;
             color: #fff;
             text-transform: capitalize;
             letter-spacing: 1px;
             margin-bottom: 15px;
          }
          p {
            font-size: 17px;
            color: #eee;
            letter-spacing: 0px;
            line-height: 19px;
          }

          .link-wrapper {
            margin-top: 10px;
          }
          a {
            display: block;
             width: 100px;
             margin: 10px 0;
            color: #fff !important;
            padding: 8px 15px;
            border: 1px solid #fff;
            letter-spacing: 1px;
            &:hover {
                background: #0033a0c4;
                border: 1px solid #002366;
            }
          }
      }
    }
    .grid_info {
      p {
        font-size: 15px;
        color: #585858;
        letter-spacing: 1px;
      }

      h3 {
        margin-top: 8px;
        text-transform: uppercase;
      }
    }
  }

    &.map-panel {
      padding: 0;
      border: none;
      border-top: 5px solid #c6c5c5;
      .panel-body {
        padding-top: 50px;
      }
    }

    &.useful-inf-panel {
      background: #c6c5c5;
      text-align: center;
      margin-top: 60px !important;
      margin-bottom: 20px !important;

      h3 {
        text-align: center;
        color: #c02020;
        padding: 7px 10px;
        display: inline-block;
        margin: 52px 0 22px 0;
        font-size: 35px;
      }
      .panel-body {
        text-align: left;
        font-size: 26px;
        h2, h3, h4 {
          font-weight: bold;
        }
      }
    }
  }
}
