html,
body {
  min-width: 1170px;
}
body {
  position: relative;
}
body.no-fixed-supported #dialog-feedback {
  position: absolute;
}
.container {
  width: 1170px !important;
}
@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}
.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}
.form-feedback .control {
  position: relative;
}
.form-feedback .control input[type="text"] {
  background: none;
  border: 2px solid white;
  font-size: 16px;
  color: #fff;
  padding: 0 12px;
  text-align: center;
  height: 25px;
}
.form-feedback .control:before {
  position: absolute;
  left: -36px;
  top: 0;
  content: "";
  display: inline-block;
}
.form-feedback .control.control-phone {
  margin-bottom: 10px;
}
.form-feedback .control.control-phone:before {
  background-image: url(/images/sprite.png);
  background-position: -237px -107px;
  width: 20px;
  height: 20px;
}
.form-feedback .control.control-name {
  margin-bottom: 17px;
}
.form-feedback .control.control-name:before {
  left: -39px;
}
.form-feedback .form-actions {
  position: relative;
}
.form-feedback .form-actions input[type="submit"] {
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
}
.form-feedback .form-actions .phone-icon {
  background: #c24949;
  width: 35px;
  height: 35px;
  border: 2px solid #f13131;
  position: absolute;
  left: -45px;
  top: 0;
  border-radius: 10px;
}
.form-feedback .form-actions .phone-icon:before {
  content: "";
  display: inline-block;
  margin: 4px;
}
.form-feedback .form-control::-webkit-input-placeholder {
  color: #fff;
}
#form-feedback .control {
  margin-bottom: 10px;
}
#form-feedback imput[type="text"] {
  margin: 0 20px;
  border: 3px solid #cdb289;
  background: none;
  width: auto;
  font-size: 15px;
  font-family: CenturyGothic;
  color: #fff;
  outline: none;
}
#form-feedback input[type="submit"] {
  margin: 30px 20px;
  background: #85939e;
  display: block;
  width: auto;
  color: #fff;
  text-align: center;
  padding: 11px 24px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  border: 0;
}
.feedback-modal-box {
  display: none;
  position: fixed;
  z-index: 1000;
  width: 300px;
  background: #fff;
  border-bottom: 1px solid #aaa;
  border-radius: 4px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-clip: padding-box;
  max-width: 300px;
  height: 186px;
  -webkit-overflow-scrolling: touch;
}
.feedback-modal-box .close {
  float: none;
  position: absolute;
  right: 4px;
  z-index: 666;
}
.feedback-modal-overlay {
  display: none;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 900;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3) !important;
}
.page .red {
  color: #c02020;
}
.page h1,
.page h2,
.page h3,
.page h4,
.page h5,
.page h6,
.page p,
.page span,
.page li {
  font-family: Open Sans;
}
.page .panel {
  text-align: left;
}
.page h1 {
  text-transform: uppercase;
  color: #666;
}
.page .list-use-margin li {
  margin-bottom: 15px;
}
table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}
table {
  width: 100%;
  margin-bottom: 18px;
}
table th,
table td {
  padding: 8px;
  line-height: 18px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #ddd;
  background: #f5f5f5;
}
table th {
  font-weight: bold;
}
table thead th {
  vertical-align: bottom;
}
table caption + thead tr:first-child th,
table caption + thead tr:first-child td,
table colgroup + thead tr:first-child th,
table colgroup + thead tr:first-child td,
table thead:first-child tr:first-child th,
table thead:first-child tr:first-child td {
  border-top: 0;
}
table tbody + tbody {
  border-top: 2px solid #ddd;
}
table table {
  background-color: #fff;
}
.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}
table {
  border: 1px solid #ddd;
  border-collapse: separate;
  *border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
table th,
table td {
  border-left: 1px solid #ddd;
}
table caption + thead tr:first-child th,
table caption + tbody tr:first-child th,
table caption + tbody tr:first-child td,
table colgroup + thead tr:first-child th,
table colgroup + tbody tr:first-child th,
table colgroup + tbody tr:first-child td,
table thead:first-child tr:first-child th,
table tbody:first-child tr:first-child th,
table tbody:first-child tr:first-child td {
  border-top: 0;
}
table thead:first-child tr:first-child > th:first-child,
table tbody:first-child tr:first-child > td:first-child,
table tbody:first-child tr:first-child > th:first-child {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
table thead:first-child tr:first-child > th:last-child,
table tbody:first-child tr:first-child > td:last-child,
table tbody:first-child tr:first-child > th:last-child {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
table thead:last-child tr:last-child > th:first-child,
table tbody:last-child tr:last-child > td:first-child,
table tbody:last-child tr:last-child > th:first-child,
table tfoot:last-child tr:last-child > td:first-child,
table tfoot:last-child tr:last-child > th:first-child {
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
table thead:last-child tr:last-child > th:last-child,
table tbody:last-child tr:last-child > td:last-child,
table tbody:last-child tr:last-child > th:last-child,
table tfoot:last-child tr:last-child > td:last-child,
table tfoot:last-child tr:last-child > th:last-child {
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
table tfoot + tbody:last-child tr:last-child td:first-child {
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
}
table tfoot + tbody:last-child tr:last-child td:last-child {
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
}
table caption + thead tr:first-child th:first-child,
table caption + tbody tr:first-child td:first-child,
table colgroup + thead tr:first-child th:first-child,
table colgroup + tbody tr:first-child td:first-child {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
table caption + thead tr:first-child th:last-child,
table caption + tbody tr:first-child td:last-child,
table colgroup + thead tr:first-child th:last-child,
table colgroup + tbody tr:first-child td:last-child {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
.table-striped tbody > tr:nth-child(odd) > td,
.table-striped tbody > tr:nth-child(odd) > th {
  background-color: #f9f9f9;
}
.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
  background-color: #f5f5f5;
}
table td[class*="span"],
table th[class*="span"],
.row-fluid table td[class*="span"],
.row-fluid table th[class*="span"] {
  display: table-cell;
  float: none;
  margin-left: 0;
}
table tbody tr.success > td {
  background-color: #dff0d8;
}
table tbody tr.error > td {
  background-color: #f2dede;
}
table tbody tr.warning > td {
  background-color: #fcf8e3;
}
table tbody tr.info > td {
  background-color: #d9edf7;
}
table tbody tr.success:hover > td {
  background-color: #d0e9c6;
}
table tbody tr.error:hover > td {
  background-color: #ebcccc;
}
table tbody tr.warning:hover > td {
  background-color: #faf2cc;
}
table tbody tr.info:hover > td {
  background-color: #c4e3f3;
}
.ic-ok {
  display: block;
  background-image: url(/images/sprite.png);
  background-position: -167px -121px;
  width: 46px;
  height: 41px;
  background: url('/images/sprites/ok-1.png');
}
.ic-znak {
  display: block;
  background-image: url(/images/sprite.png);
  background-position: -167px -121px;
  width: 46px;
  height: 41px;
  width: 30px;
  background: url('/images/sprites/exclamation-1.png');
}
.services-panel {
  text-align: center;
  padding: 13px 20px;
  background: #ebebeb;
  margin-top: 56px !important;
}
.services-panel h3 {
  color: #c02020;
  display: inline-block;
  margin: 0 0 15px 0;
  padding: 0;
  color: #F26100;
  font-family: Helvetica Neue, Arial, sans-serif;
  font-size: 26px;
}
.services-panel img {
  width: 250px;
  float: left;
  padding-right: 20px;
}
.services-panel .panel-body .services-list {
  list-style: none;
  text-align: left;
}
.services-panel .panel-body .services-list li {
  list-style: none;
  font-size: 28px;
  color: #343739;
  background: #ffffff;
  border: 4px solid #c6c5c5;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 55px;
  position: relative;
}
.services-panel .panel-body .services-list li:after {
  content: '';
  display: inline-block;
  background: #fff;
  width: 4px;
  height: 24px;
  position: absolute;
  top: 60px;
  left: 50%;
}
.services-panel .panel-body .services-list li.spec {
  margin-bottom: 141px;
}
.services-panel .panel-body .services-list li.spec:after {
  display: none;
}
.services-panel .panel-body .services-list li.spec ul {
  position: absolute;
  width: 100%;
  padding: 0;
}
.services-panel .panel-body .services-list li.spec ul li {
  display: inline-block;
  margin-top: 55px;
  padding: 0 50px;
}
.services-panel .panel-body .services-list li.spec ul li.left {
  float: left;
  margin-left: -75px;
}
.services-panel .panel-body .services-list li.spec ul li.right {
  float: right;
  margin-right: -75px;
}
.services-panel .panel-body .services-list li.spec ul li:after {
  top: -40px;
}
.about-us-panel {
  text-align: center;
}
.about-us-panel h3 {
  text-transform: uppercase;
  color: #333;
  font-family: Open Sans;
  font-weight: bold;
  background: none;
}
.about-us-panel .panel-body {
  padding-top: 79px;
}
.about-us-panel .panel-body .row {
  margin-bottom: 44px;
}
.about-us-panel .panel-body .circle {
  border: 3px solid #0033a0c4;
  width: 55px;
  height: 55px;
  background: #ededed;
  border-radius: 50px;
  display: inline-block;
  position: absolute;
  left: -55px;
  top: 10px;
  text-align: center;
}
.about-us-panel .panel-body .circle:before {
  content: "";
  display: inline-block;
  margin-top: 6px;
  transform: scale(0.7, 0.7);
  -ms-transform: scale(0.7, 0.7);
  -webkit-transform: scale(0.7, 0.7);
}
.about-us-panel .panel-body .circle.team:before {
  background-image: url(/images/sprite.png);
  background-position: -88px -183px;
  width: 43px;
  height: 40px;
}
.about-us-panel .panel-body .circle.quality:before {
  background-image: url(/images/sprite.png);
  background-position: 0px -227px;
  width: 36px;
  height: 35px;
}
.about-us-panel .panel-body .circle.prices:before {
  background-image: url(/images/sprite.png);
  background-position: -237px -42px;
  width: 22px;
  height: 40px;
  width: 26px;
}
.about-us-panel .panel-body .circle.map:before {
  background-image: url(/images/sprite.png);
  background-position: -119px -121px;
  width: 46px;
  height: 41px;
}
.about-us-panel .panel-body .circle.time:before {
  background-image: url(/images/sprite.png);
  background-position: -237px 0px;
  width: 34px;
  height: 40px;
}
.about-us-panel .panel-body .circle.discount:before {
  background-image: url(/images/sprite.png);
  background-position: -38px -227px;
  width: 35px;
  height: 35px;
}
.about-us-panel .panel-body .circle.partner:before {
  background-image: url(/images/sprite.png);
  background-position: -133px -183px;
  width: 51px;
  height: 33px;
}
.about-us-panel .panel-body h5 {
  font-family: Open Sans;
  font-size: 18px;
  line-height: 1.35;
  text-transform: uppercase;
  font-weight: bold;
  color: #565a64;
}
.about-us-panel .panel-body p {
  font-size: 18px;
  color: #565a64;
  font-family: Open Sans;
}
.about-us-panel .panel-body p + p {
  font-size: 16px;
}
.modal-box {
  display: none;
  position: fixed;
  z-index: 1000;
  background: #fff;
  border-bottom: 1px solid #aaa;
  border-radius: 4px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-clip: padding-box;
  max-height: 700px;
}
.modal-box header {
  height: 15px;
}
.modal-box .modal-body {
  text-align: center;
  padding-top: 0;
  height: inherit !important;
}
.modal-box .modal-body img {
  max-width: 100%;
  max-height: 515px;
}
.modal-overlay {
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 900;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3) !important;
}
.modal-overlay.js-modal-close {
  opacity: 0.7;
}
/*
LESS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: @icon-home-width;
}

The large array-like variables contain all information about a single icon
@icon-home: x y offset_x offset_y width height total_width total_height image_path name;

At the bottom of this section, we provide information about the spritesheet itself
@spritesheet: width height image @spritesheet-sprites;
*/
/*
The provided classes are intended to be used with the array-like variables

.icon-home {
  .sprite-width(@icon-home);
}
.icon-email {
  .sprite(@icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
/*
The `.sprites` mixin generates identical output to the CSS template
  but can be overridden inside of LESS

This must be run when you have at least 2 sprites.
  If run with a single sprite, then there will be reference errors.

.sprites(@spritesheet-sprites);
*/
@font-face {
  font-family: 'ReefBold';
  src: url('/fonts/ReefBold/ReefBold.eot');
  src: url('/fonts/ReefBold/ReefBold.eot?#iefix') format('embedded-opentype'), url('/fonts/ReefBold/ReefBold.woff') format('woff'), url('/fonts/ReefBold/ReefBold.ttf') format('truetype'), url('/fonts/ReefBold/ReefBold.svg#ReefBold') format('svg');
  font-weight: normal;
  font-style: normal;
}
#app-layout {
  font-family: "ReefBold", Helvetica, Arial, sans-serif;
  min-width: 1170px;
  padding-top: 16px;
}
#app-layout a {
  color: #485c67;
  text-decoration: none;
}
#app-layout .errors-layout {
  position: absolute;
  width: 250px;
  left: 50%;
  margin-left: -125px;
  z-index: 125;
  text-align: center;
}
#app-layout .navbar-brand {
  z-index: 1001;
}
#app-layout .s-container {
  width: 1327px;
  margin: 0 auto;
}
#app-layout #header {
  height: 52px;
  width: 100%;
  position: absolute;
  z-index: 5;
  top: 0;
  background-color: #f5f5f5;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
#app-layout #header #header-menu {
  margin: 0 auto;
  width: 1100px;
}
#app-layout #header #logo .logo-brand {
  z-index: 1001;
  padding: 15px 15px;
}
#app-layout #header #logo .logo-brand.under {
  position: absolute;
  width: 170px;
  left: 370px;
  top: 17px;
}
#app-layout #header .wrapper {
  position: relative;
}
#app-layout #header #brandName {
  position: absolute;
  top: 41px;
  max-width: 235px;
  font-size: 25px;
  line-height: 25px;
  left: 160px;
  color: #fff;
}
#app-layout #header .nav li {
  float: left;
}
#app-layout #header .nav li a {
  font-size: 16px;
  cursor: pointer;
  /*color: #F26100;*/
  color: #666;
  text-transform: uppercase;
  text-align: center;
  background: #f5f5f5;
}
#app-layout #header .nav li a:hover {
  background: #002366;
  color: #fff;
}
#app-layout #header .nav.navbar-right {
  float: right;
}
#app-layout #header .nav.navbar-abs {
  position: absolute;
  right: 40px;
}
#app-layout #header .navbar-nav > li > .dropdown-menu.activate {
  display: block;
}
#app-layout footer {
  background: #252525;
  font-size: 18px;
  color: #fff;
}
#app-layout footer a {
  color: #fff;
}
#app-layout footer .container {
  position: relative;
}
#app-layout footer hr {
  position: absolute;
  width: 100%;
  border-color: #ff4040;
  top: 30px;
  border-top-width: 3px;
}
#app-layout footer .nav {
  float: left;
  position: relative;
  margin-top: 15px;
  text-align: center;
  font-size: 14px;
}
#app-layout footer .nav.address {
  width: 390px;
  text-align: left;
}
#app-layout footer .nav.address p {
  margin-bottom: 0;
  color: #fff;
  font-size: 25px;
}
#app-layout footer .nav.address p.email a {
  font-weight: normal;
}
#app-layout footer .nav.address p a {
  font-weight: bold;
}
#app-layout footer .nav.contact-info {
  width: 570px;
}
#app-layout footer .nav.contact-info p {
  margin: 0;
  text-align: center;
  font-size: 25px;
}
#app-layout footer .nav.contact-info a {
  color: #fff;
  font-size: 25px;
}
#app-layout footer .nav.phones {
  color: #fff;
  text-align: right;
  font-size: 25px;
}
#app-layout #content {
  margin-top: 68px;
}
#app-layout #scrollup {
  position: fixed;
  /* фиксированная позиция */
  opacity: 0.8;
  /* прозрачность */
  padding: 15px 10px 10px;
  /* отступы */
  background: #aaa;
  border-radius: 5px;
  /* скругление углов */
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  right: 10px;
  /* отступ слева */
  bottom: 40px;
  display: none;
  /* спрятать блок */
  cursor: pointer;
}
#app-layout hr {
  border-top: 2px solid #d4d4d4;
}
#app-layout .page {
  margin-top: 32px;
}
