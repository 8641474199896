/*
LESS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: @icon-home-width;
}

The large array-like variables contain all information about a single icon
@icon-home: x y offset_x offset_y width height total_width total_height image_path name;

At the bottom of this section, we provide information about the spritesheet itself
@spritesheet: width height image @spritesheet-sprites;
*/
@icon-discount-name: 'icon-discount';
@icon-discount-x: 38px;
@icon-discount-y: 227px;
@icon-discount-offset-x: -38px;
@icon-discount-offset-y: -227px;
@icon-discount-width: 35px;
@icon-discount-height: 35px;
@icon-discount-total-width: 271px;
@icon-discount-total-height: 262px;
@icon-discount-image: '/images/sprite.png';
@icon-discount: 38px 227px -38px -227px 35px 35px 271px 262px '/images/sprite.png' 'icon-discount';
@icon-doc-name: 'icon-doc';
@icon-doc-x: 173px;
@icon-doc-y: 0px;
@icon-doc-offset-x: -173px;
@icon-doc-offset-y: 0px;
@icon-doc-width: 62px;
@icon-doc-height: 62px;
@icon-doc-total-width: 271px;
@icon-doc-total-height: 262px;
@icon-doc-image: '/images/sprite.png';
@icon-doc: 173px 0px -173px 0px 62px 62px 271px 262px '/images/sprite.png' 'icon-doc';
@icon-experience-name: 'icon-experience';
@icon-experience-x: 62px;
@icon-experience-y: 121px;
@icon-experience-offset-x: -62px;
@icon-experience-offset-y: -121px;
@icon-experience-width: 55px;
@icon-experience-height: 56px;
@icon-experience-total-width: 271px;
@icon-experience-total-height: 262px;
@icon-experience-image: '/images/sprite.png';
@icon-experience: 62px 121px -62px -121px 55px 56px 271px 262px '/images/sprite.png' 'icon-experience';
@icon-grafic-name: 'icon-grafic';
@icon-grafic-x: 69px;
@icon-grafic-y: 58px;
@icon-grafic-offset-x: -69px;
@icon-grafic-offset-y: -58px;
@icon-grafic-width: 70px;
@icon-grafic-height: 56px;
@icon-grafic-total-width: 271px;
@icon-grafic-total-height: 262px;
@icon-grafic-image: '/images/sprite.png';
@icon-grafic: 69px 58px -69px -58px 70px 56px 271px 262px '/images/sprite.png' 'icon-grafic';
@icon-maestro-name: 'icon-maestro';
@icon-maestro-x: 0px;
@icon-maestro-y: 0px;
@icon-maestro-offset-x: 0px;
@icon-maestro-offset-y: 0px;
@icon-maestro-width: 94px;
@icon-maestro-height: 56px;
@icon-maestro-total-width: 271px;
@icon-maestro-total-height: 262px;
@icon-maestro-image: '/images/sprite.png';
@icon-maestro: 0px 0px 0px 0px 94px 56px 271px 262px '/images/sprite.png' 'icon-maestro';
@icon-map-name: 'icon-map';
@icon-map-x: 119px;
@icon-map-y: 121px;
@icon-map-offset-x: -119px;
@icon-map-offset-y: -121px;
@icon-map-width: 46px;
@icon-map-height: 41px;
@icon-map-total-width: 271px;
@icon-map-total-height: 262px;
@icon-map-image: '/images/sprite.png';
@icon-map: 119px 121px -119px -121px 46px 41px 271px 262px '/images/sprite.png' 'icon-map';
@icon-mastercard-visa-name: 'icon-mastercard_visa';
@icon-mastercard-visa-x: 0px;
@icon-mastercard-visa-y: 121px;
@icon-mastercard-visa-offset-x: 0px;
@icon-mastercard-visa-offset-y: -121px;
@icon-mastercard-visa-width: 60px;
@icon-mastercard-visa-height: 60px;
@icon-mastercard-visa-total-width: 271px;
@icon-mastercard-visa-total-height: 262px;
@icon-mastercard-visa-image: '/images/sprite.png';
@icon-mastercard-visa: 0px 121px 0px -121px 60px 60px 271px 262px '/images/sprite.png' 'icon-mastercard_visa';
@icon-next-arrow-name: 'icon-next-arrow';
@icon-next-arrow-x: 261px;
@icon-next-arrow-y: 42px;
@icon-next-arrow-offset-x: -261px;
@icon-next-arrow-offset-y: -42px;
@icon-next-arrow-width: 9px;
@icon-next-arrow-height: 16px;
@icon-next-arrow-total-width: 271px;
@icon-next-arrow-total-height: 262px;
@icon-next-arrow-image: '/images/sprite.png';
@icon-next-arrow: 261px 42px -261px -42px 9px 16px 271px 262px '/images/sprite.png' 'icon-next-arrow';
@icon-ok-name: 'icon-ok';
@icon-ok-x: 167px;
@icon-ok-y: 121px;
@icon-ok-offset-x: -167px;
@icon-ok-offset-y: -121px;
@icon-ok-width: 46px;
@icon-ok-height: 41px;
@icon-ok-total-width: 271px;
@icon-ok-total-height: 262px;
@icon-ok-image: '/images/sprite.png';
@icon-ok: 167px 121px -167px -121px 46px 41px 271px 262px '/images/sprite.png' 'icon-ok';
@icon-partner-name: 'icon-partner';
@icon-partner-x: 133px;
@icon-partner-y: 183px;
@icon-partner-offset-x: -133px;
@icon-partner-offset-y: -183px;
@icon-partner-width: 51px;
@icon-partner-height: 33px;
@icon-partner-total-width: 271px;
@icon-partner-total-height: 262px;
@icon-partner-image: '/images/sprite.png';
@icon-partner: 133px 183px -133px -183px 51px 33px 271px 262px '/images/sprite.png' 'icon-partner';
@icon-phone-name: 'icon-phone';
@icon-phone-x: 237px;
@icon-phone-y: 107px;
@icon-phone-offset-x: -237px;
@icon-phone-offset-y: -107px;
@icon-phone-width: 20px;
@icon-phone-height: 20px;
@icon-phone-total-width: 271px;
@icon-phone-total-height: 262px;
@icon-phone-image: '/images/sprite.png';
@icon-phone: 237px 107px -237px -107px 20px 20px 271px 262px '/images/sprite.png' 'icon-phone';
@icon-prev-arrow-name: 'icon-prev-arrow';
@icon-prev-arrow-x: 261px;
@icon-prev-arrow-y: 60px;
@icon-prev-arrow-offset-x: -261px;
@icon-prev-arrow-offset-y: -60px;
@icon-prev-arrow-width: 8px;
@icon-prev-arrow-height: 16px;
@icon-prev-arrow-total-width: 271px;
@icon-prev-arrow-total-height: 262px;
@icon-prev-arrow-image: '/images/sprite.png';
@icon-prev-arrow: 261px 60px -261px -60px 8px 16px 271px 262px '/images/sprite.png' 'icon-prev-arrow';
@icon-price-name: 'icon-price';
@icon-price-x: 237px;
@icon-price-y: 42px;
@icon-price-offset-x: -237px;
@icon-price-offset-y: -42px;
@icon-price-width: 22px;
@icon-price-height: 40px;
@icon-price-total-width: 271px;
@icon-price-total-height: 262px;
@icon-price-image: '/images/sprite.png';
@icon-price: 237px 42px -237px -42px 22px 40px 271px 262px '/images/sprite.png' 'icon-price';
@icon-quality-name: 'icon-quality';
@icon-quality-x: 0px;
@icon-quality-y: 227px;
@icon-quality-offset-x: 0px;
@icon-quality-offset-y: -227px;
@icon-quality-width: 36px;
@icon-quality-height: 35px;
@icon-quality-total-width: 271px;
@icon-quality-total-height: 262px;
@icon-quality-image: '/images/sprite.png';
@icon-quality: 0px 227px 0px -227px 36px 35px 271px 262px '/images/sprite.png' 'icon-quality';
@icon-settings-name: 'icon-settings';
@icon-settings-x: 0px;
@icon-settings-y: 58px;
@icon-settings-offset-x: 0px;
@icon-settings-offset-y: -58px;
@icon-settings-width: 67px;
@icon-settings-height: 61px;
@icon-settings-total-width: 271px;
@icon-settings-total-height: 262px;
@icon-settings-image: '/images/sprite.png';
@icon-settings: 0px 58px 0px -58px 67px 61px 271px 262px '/images/sprite.png' 'icon-settings';
@icon-slider-next-control-name: 'icon-slider-next-control';
@icon-slider-next-control-x: 44px;
@icon-slider-next-control-y: 183px;
@icon-slider-next-control-offset-x: -44px;
@icon-slider-next-control-offset-y: -183px;
@icon-slider-next-control-width: 42px;
@icon-slider-next-control-height: 42px;
@icon-slider-next-control-total-width: 271px;
@icon-slider-next-control-total-height: 262px;
@icon-slider-next-control-image: '/images/sprite.png';
@icon-slider-next-control: 44px 183px -44px -183px 42px 42px 271px 262px '/images/sprite.png' 'icon-slider-next-control';
@icon-slider-prev-control-name: 'icon-slider-prev-control';
@icon-slider-prev-control-x: 0px;
@icon-slider-prev-control-y: 183px;
@icon-slider-prev-control-offset-x: 0px;
@icon-slider-prev-control-offset-y: -183px;
@icon-slider-prev-control-width: 42px;
@icon-slider-prev-control-height: 42px;
@icon-slider-prev-control-total-width: 271px;
@icon-slider-prev-control-total-height: 262px;
@icon-slider-prev-control-image: '/images/sprite.png';
@icon-slider-prev-control: 0px 183px 0px -183px 42px 42px 271px 262px '/images/sprite.png' 'icon-slider-prev-control';
@icon-team-name: 'icon-team';
@icon-team-x: 88px;
@icon-team-y: 183px;
@icon-team-offset-x: -88px;
@icon-team-offset-y: -183px;
@icon-team-width: 43px;
@icon-team-height: 40px;
@icon-team-total-width: 271px;
@icon-team-total-height: 262px;
@icon-team-image: '/images/sprite.png';
@icon-team: 88px 183px -88px -183px 43px 40px 271px 262px '/images/sprite.png' 'icon-team';
@icon-time-name: 'icon-time';
@icon-time-x: 237px;
@icon-time-y: 0px;
@icon-time-offset-x: -237px;
@icon-time-offset-y: 0px;
@icon-time-width: 34px;
@icon-time-height: 40px;
@icon-time-total-width: 271px;
@icon-time-total-height: 262px;
@icon-time-image: '/images/sprite.png';
@icon-time: 237px 0px -237px 0px 34px 40px 271px 262px '/images/sprite.png' 'icon-time';
@icon-velcom-name: 'icon-velcom';
@icon-velcom-x: 237px;
@icon-velcom-y: 84px;
@icon-velcom-offset-x: -237px;
@icon-velcom-offset-y: -84px;
@icon-velcom-width: 28px;
@icon-velcom-height: 21px;
@icon-velcom-total-width: 271px;
@icon-velcom-total-height: 262px;
@icon-velcom-image: '/images/sprite.png';
@icon-velcom: 237px 84px -237px -84px 28px 21px 271px 262px '/images/sprite.png' 'icon-velcom';
@icon-worker-name: 'icon-worker';
@icon-worker-x: 96px;
@icon-worker-y: 0px;
@icon-worker-offset-x: -96px;
@icon-worker-offset-y: 0px;
@icon-worker-width: 75px;
@icon-worker-height: 55px;
@icon-worker-total-width: 271px;
@icon-worker-total-height: 262px;
@icon-worker-image: '/images/sprite.png';
@icon-worker: 96px 0px -96px 0px 75px 55px 271px 262px '/images/sprite.png' 'icon-worker';
@icon-zoom-name: 'icon-zoom';
@icon-zoom-x: 173px;
@icon-zoom-y: 64px;
@icon-zoom-offset-x: -173px;
@icon-zoom-offset-y: -64px;
@icon-zoom-width: 54px;
@icon-zoom-height: 45px;
@icon-zoom-total-width: 271px;
@icon-zoom-total-height: 262px;
@icon-zoom-image: '/images/sprite.png';
@icon-zoom: 173px 64px -173px -64px 54px 45px 271px 262px '/images/sprite.png' 'icon-zoom';
@spritesheet-width: 271px;
@spritesheet-height: 262px;
@spritesheet-image: '/images/sprite.png';
@spritesheet-sprites: @icon-discount @icon-doc @icon-experience @icon-grafic @icon-maestro @icon-map @icon-mastercard-visa @icon-next-arrow @icon-ok @icon-partner @icon-phone @icon-prev-arrow @icon-price @icon-quality @icon-settings @icon-slider-next-control @icon-slider-prev-control @icon-team @icon-time @icon-velcom @icon-worker @icon-zoom;
@spritesheet: 271px 262px '/images/sprite.png' @spritesheet-sprites;

/*
The provided classes are intended to be used with the array-like variables

.icon-home {
  .sprite-width(@icon-home);
}
.icon-email {
  .sprite(@icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.sprite-width(@sprite) {
  width: extract(@sprite, 5);
}

.sprite-height(@sprite) {
  height: extract(@sprite, 6);
}

.sprite-position(@sprite) {
  @sprite-offset-x: extract(@sprite, 3);
  @sprite-offset-y: extract(@sprite, 4);
  background-position: @sprite-offset-x @sprite-offset-y;
}

.sprite-image(@sprite) {
  @sprite-image: extract(@sprite, 9);
  @sprite-image-bare: ~`"@{sprite-image}".slice(1, -1)`;
  background-image: url(@sprite-image-bare);
}

.sprite(@sprite) {
  .sprite-image(@sprite);
  .sprite-position(@sprite);
  .sprite-width(@sprite);
  .sprite-height(@sprite);
}

/*
The `.sprites` mixin generates identical output to the CSS template
  but can be overridden inside of LESS

This must be run when you have at least 2 sprites.
  If run with a single sprite, then there will be reference errors.

.sprites(@spritesheet-sprites);
*/
.sprites(@sprites, @i: 1) when (@i <= length(@sprites)) {
  @sprite: extract(@sprites, @i);
  @sprite-name: e(extract(@sprite, 10));
  .@{sprite-name} {
    .sprite(@sprite);
  }
  .sprites(@sprites, @i + 1);
}
