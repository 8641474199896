
.modal-box {
    display: none;
    position: fixed;
    z-index: 1000;
    background: #fff;
    border-bottom: 1px solid #aaa;
    border-radius: 4px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    border: 1px solid rgba(0, 0, 0, .1);
    background-clip: padding-box;
    max-height: 700px;

    header {
      height: 15px;
    }

    .modal-body {
      text-align: center;
      padding-top: 0;
      height: inherit !important;
      img {
          max-width: 100%;
          max-height: 515px
      }
    }
}

.modal-overlay {
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 900;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .3) !important;
    &.js-modal-close {
        opacity: .7
    }
}
