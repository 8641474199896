@import "variables.less";
@import "sprites.less";

.page {
  .red {
    color: #c02020;
  }
  h1, h2, h3, h4, h5, h6, p, span, li {
    //font-family: Arial,Helvetica,sans-serif;
    font-family: Open Sans;
  }
  .panel {
    text-align: left;
  }
  h1 {
    text-transform: uppercase;
    color: #666;
  }
  .list-use-margin {
    li {
      margin-bottom: 15px;
    }
  }
}

table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}
table {
  width: 100%;
  margin-bottom: 18px;
}
table th,
table td {
  padding: 8px;
  line-height: 18px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #ddd;
  background: #f5f5f5;
}
table th {
  font-weight: bold;
}
table thead th {
  vertical-align: bottom;
}
table caption + thead tr:first-child th,
table caption + thead tr:first-child td,
table colgroup + thead tr:first-child th,
table colgroup + thead tr:first-child td,
table thead:first-child tr:first-child th,
table thead:first-child tr:first-child td {
  border-top: 0;
}
table tbody + tbody {
  border-top: 2px solid #ddd;
}
table table {
  background-color: #fff;
}
.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}
table {
  border: 1px solid #ddd;
  border-collapse: separate;
  *border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
table th,
table td {
  border-left: 1px solid #ddd;
}
table caption + thead tr:first-child th,
table caption + tbody tr:first-child th,
table caption + tbody tr:first-child td,
table colgroup + thead tr:first-child th,
table colgroup + tbody tr:first-child th,
table colgroup + tbody tr:first-child td,
table thead:first-child tr:first-child th,
table tbody:first-child tr:first-child th,
table tbody:first-child tr:first-child td {
  border-top: 0;
}
table thead:first-child tr:first-child > th:first-child,
table tbody:first-child tr:first-child > td:first-child,
table tbody:first-child tr:first-child > th:first-child {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
table thead:first-child tr:first-child > th:last-child,
table tbody:first-child tr:first-child > td:last-child,
table tbody:first-child tr:first-child > th:last-child {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
table thead:last-child tr:last-child > th:first-child,
table tbody:last-child tr:last-child > td:first-child,
table tbody:last-child tr:last-child > th:first-child,
table tfoot:last-child tr:last-child > td:first-child,
table tfoot:last-child tr:last-child > th:first-child {
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
table thead:last-child tr:last-child > th:last-child,
table tbody:last-child tr:last-child > td:last-child,
table tbody:last-child tr:last-child > th:last-child,
table tfoot:last-child tr:last-child > td:last-child,
table tfoot:last-child tr:last-child > th:last-child {
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
table tfoot + tbody:last-child tr:last-child td:first-child {
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
}
table tfoot + tbody:last-child tr:last-child td:last-child {
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
}
table caption + thead tr:first-child th:first-child,
table caption + tbody tr:first-child td:first-child,
table colgroup + thead tr:first-child th:first-child,
table colgroup + tbody tr:first-child td:first-child {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
table caption + thead tr:first-child th:last-child,
table caption + tbody tr:first-child td:last-child,
table colgroup + thead tr:first-child th:last-child,
table colgroup + tbody tr:first-child td:last-child {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
.table-striped tbody > tr:nth-child(odd) > td,
.table-striped tbody > tr:nth-child(odd) > th {
  background-color: #f9f9f9;
}
.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
  background-color: #f5f5f5;
}
table td[class*="span"],
table th[class*="span"],
.row-fluid table td[class*="span"],
.row-fluid table th[class*="span"] {
  display: table-cell;
  float: none;
  margin-left: 0;
}

table tbody tr.success > td {
  background-color: #dff0d8;
}
table tbody tr.error > td {
  background-color: #f2dede;
}
table tbody tr.warning > td {
  background-color: #fcf8e3;
}
table tbody tr.info > td {
  background-color: #d9edf7;
}
table tbody tr.success:hover > td {
  background-color: #d0e9c6;
}
table tbody tr.error:hover > td {
  background-color: #ebcccc;
}
table tbody tr.warning:hover > td {
  background-color: #faf2cc;
}
table tbody tr.info:hover > td {
  background-color: #c4e3f3;
}

.ic-ok {
  display: block;
  .sprite(@icon-ok);
  background: url('/images/sprites/ok-1.png');
}

.ic-znak {
  display: block;
  .sprite(@icon-ok);
  width: 30px;
  background: url('/images/sprites/exclamation-1.png');
}

.services-panel {
  text-align: center;
  padding: 13px 20px;
  background: #ebebeb;
  margin-top: 56px !important;

  h3 {
    color: #c02020;
    display: inline-block;
    margin: 0 0 15px 0;
    padding: 0;
    color: #F26100;
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 26px;
  }

  img {
    width: 250px;
    float: left;
    padding-right: 20px;
  }


  .panel-body {
    .services-list {
      list-style: none;
      text-align: left;
      li {
        list-style: none;
        font-size: 28px;
        color: #343739;
        background: #ffffff;
        border: 4px solid #c6c5c5;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        margin-bottom: 55px;
        position: relative;
        &:after {
          content: '';
          display: inline-block;
          background: #fff;
          width: 4px;
          height: 24px;
          position: absolute;
          top: 60px;
          left: 50%;
        }

        &.spec {
          margin-bottom: 141px;
          &:after {
            display: none;
          }
          ul {
            position: absolute;
            width: 100%;
            padding: 0;
            li {
              display: inline-block;
              margin-top: 55px;
              padding: 0 50px;
              &.left {
                float: left;
                margin-left: -75px
              }
              &.right {
                float: right;
                margin-right: -75px
              }
              &:after {
                top: -40px;
              }
            }
          }
        }
      }
    }
  }
}

.about-us-panel {
  text-align: center;

  h3 {
    text-transform: uppercase;
    color: #333;
    font-family: Open Sans;
    font-weight: bold;
    background: none;
  }

  .panel-body {
    padding-top: 79px;

    .row {
      margin-bottom: 44px
    }

    .circle {
      border: 3px solid #0033a0c4;
      width: 55px;
      height: 55px;
      background: #ededed;
      border-radius: 50px;
      display: inline-block;
      position: absolute;
      left: -55px;
      top: 10px;
      text-align: center;

      &:before {
        content: "";
        display: inline-block;
        margin-top: 6px;
        transform: scale(0.7, 0.7);
        -ms-transform: scale(0.7, 0.7);
        -webkit-transform: scale(0.7, 0.7);
      }

      &.team:before {
        .sprite(@icon-team);
      }
      &.quality:before {
        .sprite(@icon-quality);
      }
      &.prices:before {
        .sprite(@icon-price);
        width: 26px;
      }
      &.map:before {
        .sprite(@icon-map);
      }
      &.time:before {
        .sprite(@icon-time);
      }
      &.discount:before {
        .sprite(@icon-discount);
      }
      &.partner:before {
        .sprite(@icon-partner);
      }
    }

    h5 {
      font-family: Open Sans;
      font-size: 18px;
      line-height: 1.35;
      text-transform: uppercase;
      font-weight: bold;
      color: #565a64;
    }

    p {
      font-size: 18px;
      color: #565a64;
      font-family: Open Sans;
      & + p {
        font-size: 16px;
      }
    }
  }
}
