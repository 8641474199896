@pTheme: "";

.font-face(@fontName: sans-serif; @fontWeight: normal; @fontStyle: normal) {
  @font-face {
    font-family: @fontName;
    src: url('@{pTheme}/fonts/@{fontName}/@{fontName}.eot');
    src: url('@{pTheme}/fonts/@{fontName}/@{fontName}.eot?#iefix') format('embedded-opentype'),
    url('@{pTheme}/fonts/@{fontName}/@{fontName}.woff') format('woff'),
    url('@{pTheme}/fonts/@{fontName}/@{fontName}.ttf') format('truetype'),
    url('@{pTheme}/fonts/@{fontName}/@{fontName}.svg#@{fontName}') format('svg');
    font-weight: @fontWeight;
    font-style: @fontStyle;
  }
}