@import "variables.less";
@import "init.less";
@import "partials/feedback.less";
@import "content.less";
@import "mixins.less";
@import "modal.less";
@import "sprites.less";

.font-face('ReefBold');

#app-layout {
  font-family: "ReefBold",Helvetica,Arial,sans-serif;
  min-width: 1170px;
  padding-top: 16px;

  a {
    color: #485c67;
    text-decoration: none;
  }
  .errors-layout {
    position: absolute;
    width: 250px;
    left: 50%;
    margin-left: -125px;
    z-index: 125;
    text-align: center;
  }
  .navbar-brand{
    z-index: 1001;
  }

  .s-container {
    width: 1327px;
    margin: 0 auto;
  }

  #header {
    height: 52px;
    width: 100%;
    position: absolute;
    z-index: 5;
    top: 0;
    background-color: #f5f5f5;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    //margin-top: 16px;
    #header-menu {
      margin: 0 auto;
      width: 1100px;
    }
    #logo {
      .logo-brand {
        z-index: 1001;
        padding: 15px 15px;
        &.under {
          position: absolute;
          width: 170px;
          left: 370px;
          top: 17px
        }
      }
    }
    .wrapper {
      position: relative;
    }
    #brandName {
      position: absolute;
      top: 41px;
      max-width: 235px;
      font-size: 25px;
      line-height: 25px;
      left: 160px;
      color: #fff;

      span.gray {
        //color: rgba(92, 92, 92, 0.76);
        //text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      }
    }
    .nav {
      li {
        float: left;
        //border: 1px solid #b7434e;
        a {
          font-size: 16px;
          cursor: pointer;
          /*color: #F26100;*/
          color: #666;
          text-transform: uppercase;
          text-align: center;
          background: #f5f5f5;
          &:hover {
            background: #002366;
            color: #fff;
          }
        }
      }
      &.navbar-right {
        float: right;
      }
      &.navbar-abs {
        position: absolute;
        right: 40px;
      }
    }
    .navbar-nav > li > .dropdown-menu.activate {
      display: block;
    }

  }

  .page {

  }
  footer {
    background: #252525;
    font-size: 18px;
    color: @white-color;

    a {
      color: @white-color;
    }

    .container {
      position: relative;
    }
    hr {
      position: absolute;
      width: 100%;
      border-color: #ff4040;
      top: 30px;
      border-top-width: 3px;
    }

    .nav {
      float: left;
      position: relative;
      margin-top: 15px;
      text-align: center;
      font-size: 14px;
      &.address {
        width: 390px;
        text-align: left;
        p {
          margin-bottom: 0;
          color: #fff;
          font-size: 25px;
          &.email a {
            font-weight: normal;
          }
          a {
            &:extend(#app-layout > nav.navbar-default .contact-info p a);
            font-weight: bold;
            &:hover {
              &:extend(#app-layout > nav.navbar-default .contact-info p a:hover);
            }
          }
        }
      }
      &.contact-info {
        width: 570px;

        p {
          margin: 0;
          text-align: center;
          font-size: 25px;
        }
        a {
          color: @white-color;
          font-size: 25px;
        }
      }
      &.phones {
        color: @white-color;
        text-align: right;
        font-size: 25px;
      }
    }
  }
  #content {
    margin-top: 68px;
  }
  #scrollup {
    position: fixed; /* фиксированная позиция */
    opacity: 0.8; /* прозрачность */
    padding: 15px 10px 10px; /* отступы */
    background: #aaa;
    border-radius: 5px; /* скругление углов */
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    right: 10px; /* отступ слева */
    bottom: 40px;
    display: none; /* спрятать блок */
    cursor: pointer;
  }
  hr {
    border-top: 2px solid #d4d4d4
  }

  .page {
    margin-top: 32px;
  }
}
