/*
LESS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: @icon-home-width;
}

The large array-like variables contain all information about a single icon
@icon-home: x y offset_x offset_y width height total_width total_height image_path name;

At the bottom of this section, we provide information about the spritesheet itself
@spritesheet: width height image @spritesheet-sprites;
*/
/*
The provided classes are intended to be used with the array-like variables

.icon-home {
  .sprite-width(@icon-home);
}
.icon-email {
  .sprite(@icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
/*
The `.sprites` mixin generates identical output to the CSS template
  but can be overridden inside of LESS

This must be run when you have at least 2 sprites.
  If run with a single sprite, then there will be reference errors.

.sprites(@spritesheet-sprites);
*/
.carousel {
  max-width: 1080px;
  margin: 0 auto 50px auto;
  width: 100%;
}
.carousel-wrapper {
  margin: 10px 30px;
  overflow: hidden;
  position: relative;
}
.carousel-items {
  width: 10000px;
  position: relative;
}
.carousel-block {
  float: left;
  width: auto;
  padding: 10px 58px 10px 0px;
}
.carousel-block img {
  display: block;
  height: 80px;
}
.carousel-button-left a,
.carousel-button-right a {
  width: 25px;
  height: 36px;
  position: relative;
  top: 80px;
  cursor: pointer;
  text-decoration: none;
  display: none;
}
.carousel-button-left a {
  float: left;
  background-image: url(/images/sprite.png);
  background-position: 0px -183px;
  width: 42px;
  height: 42px;
}
.carousel-button-right a {
  float: right;
  background-image: url(/images/sprite.png);
  background-position: -44px -183px;
  width: 42px;
  height: 42px;
}
.shadow {
  padding: 10px 15px;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
  position: relative;
}
/*@highdensity: ~"only screen and (-webkit-min-device-pixel-ratio: 1.5)",
~"only screen and (min--moz-device-pixel-ratio: 1.5)",
~"only screen and (-o-min-device-pixel-ratio: 3/2)",
~"only screen and (min-device-pixel-ratio: 1.5)";
@mobile:      ~"only screen and (max-width: 529px)";
@tablet:      ~"only screen and (min-width: 530px) and (max-width: 949px)";
@desktop:     ~"only screen and (min-width: 950px) and (max-width: 1128px)";
@desktop-xl:  ~"only screen and (min-width: 1129px)";*/
.b-slideshow {
  margin: 0 auto;
  position: relative;
  height: 424px;
  background: #fff;
}
@media only screen and (max-width: 767px) {
  .b-slideshow {
    height: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .b-slideshow {
    height: 290px;
  }
}
.b-slideshow .b-slideshow-area {
  height: 424px;
  overflow: hidden;
  background: #fff;
}
@media only screen and (max-width: 767px) {
  .b-slideshow .b-slideshow-area {
    min-height: 120px;
    height: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .b-slideshow .b-slideshow-area {
    height: 290px;
  }
}
.b-slideshow .b-slideshow-area .b-click {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 190px;
  background: #464544;
}
@media only screen and (max-width: 767px) {
  .b-slideshow .b-slideshow-area .b-click {
    top: 40px;
    opacity: .65;
  }
  .b-slideshow .b-slideshow-area .b-click.prev {
    left: 0 !important;
  }
  .b-slideshow .b-slideshow-area .b-click.next {
    right: 0 !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .b-slideshow .b-slideshow-area .b-click {
    top: 90px;
    opacity: .65;
  }
  .b-slideshow .b-slideshow-area .b-click.prev {
    left: 0 !important;
  }
  .b-slideshow .b-slideshow-area .b-click.next {
    right: 0 !important;
  }
}
.b-slideshow .b-slideshow-area .b-click.prev {
  left: -70px;
  background-image: url(/images/sprite.png);
  background-position: 0px -183px;
  width: 42px;
  height: 42px;
}
.b-slideshow .b-slideshow-area .b-click.next {
  right: -70px;
  background-image: url(/images/sprite.png);
  background-position: -44px -183px;
  width: 42px;
  height: 42px;
  z-index: 2;
}
.b-slideshow .b-slideshow-area .b-click:hover {
  opacity: .8;
}
.b-slideshow .b-slideshow-area .b-slideshow-list {
  position: relative;
  left: 0;
  letter-spacing: -0.25em;
  margin: 0px;
  padding: 0px;
}
.b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  font-size: 3.75em;
  font-weight: 400;
  -webkit-background-size: cover;
  text-align: center;
  width: 100%;
  height: 423px;
}
@media only screen and (max-width: 767px) {
  .b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item {
    height: auto;
    position: static;
  }
  .b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item:not(.view) {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item {
    height: 290px;
  }
}
.b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item.view {
  display: inline-block;
}
.b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item img {
  width: 100%;
  max-width: 1170px;
}
.b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item .text {
  position: absolute;
  width: 580px;
  height: 120px;
  top: 50%;
  left: 50%;
  margin-left: -290px;
  margin-top: -60px;
  color: #fff;
  font-size: 37px;
  font-family: 'CenturyGothic';
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0;
}
.b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item .text span {
  font-size: 47px;
  padding-bottom: 15px;
  display: block;
}
.b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item .slide-content {
  font-size: 21px;
  color: #4e4e4e;
  text-transform: uppercase;
  letter-spacing: -1px;
  font-weight: bold;
  margin: 0;
  padding: 0 10px;
  width: 390px;
  z-index: 2;
  font-family: Open Sans;
}
.b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item .slide-text {
  font-size: 15px;
  font-weight: normal;
  margin-top: 10px;
}
.b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item .slide-text p {
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item .slide-icon {
    display: block;
  }
}
.b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item .content-overlay {
  background: #fff;
  opacity: 0.8;
  padding: 15px 15px 15px 35px;
  position: absolute;
  left: 50%;
  margin-left: -585px;
  bottom: 20px;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item .content-overlay {
    box-sizing: border-box;
    width: 100%;
    left: 0;
    padding: 5px;
    position: static;
  }
  .b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item .content-overlay h3.slide-content {
    width: 100%;
    font-size: 20px;
    padding: 0;
  }
  .b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item .content-overlay h5.slide-content {
    width: 100%;
    font-size: 14px;
    padding: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item .content-overlay {
    left: 70px;
  }
}
.b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item .btn {
  background: #ff065e;
  color: #fff;
  padding: 10px 20px;
  vertical-align: bottom;
  margin-left: 10px;
}
@media only screen and (max-width: 767px) {
  .b-slideshow .b-slideshow-area .b-slideshow-list .b-slideshow-item .btn {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 2px 5px;
    vertical-align: top;
    margin-left: 0;
    display: block;
  }
}
.slide .partners-icons {
  width: 500px;
  position: absolute;
  left: 473px;
  top: 10px;
}
.slide .partners-icons .partner-block {
  width: 60px;
  height: 60px;
  float: left;
  margin-right: 10px;
}
.slide .phones {
  position: absolute;
  right: 30px;
  top: 20px;
  width: 186px;
  font-size: 13px;
  letter-spacing: initial;
  font-family: Open Sans;
  color: #4e4e4e;
}
.slide .phones p {
  font-size: 14px;
  margin: 0;
}
.slide .push-form {
  border: none;
  font-family: 'Questrial', sans-serif;
  color: #fff;
  background: #0033a0c4;
  cursor: pointer;
  padding: 4px 14px;
  letter-spacing: 1px;
  outline: none;
  font-size: 13px;
  font-weight: 100;
  border-bottom: 3px solid #0033a0c4;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  margin-top: 3em;
  margin: 10px 11px;
  width: 156px;
}
.slide .push-form:hover {
  background: #002366;
  border-bottom: 3px solid #021233c4;
}
.front .col-3 {
  width: 25%;
  float: left;
}
.front .col-4 {
  width: 33.33333333%;
  float: left;
}
.front .col-6 {
  width: 50%;
  float: left;
}
.front .panel-heading {
  text-align: center;
  background: none;
  border: none;
  color: #40505d;
  text-transform: uppercase;
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 0;
}
.front .panel {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
}
.front .panel.main-panel {
  border: none;
  box-shadow: none;
  height: 560px;
  background-image: linear-gradient(rgba(92, 107, 142, 0.7), rgba(255, 255, 255, 0.7)), url(/images/logo-t.jpg);
}
.front .panel.main-panel .container {
  padding: 0;
}
.front .panel.main-panel .panel-body {
  padding: 0;
  position: relative;
}
.front .panel.main-panel .panel-body .top {
  height: 434px;
}
.front .panel.main-panel .panel-body .top .push-form {
  border: none;
  font-family: 'Questrial', sans-serif;
  color: #fff;
  background: #ffffffc4;
  cursor: pointer;
  padding: 10px 35px;
  letter-spacing: 1px;
  outline: none;
  font-size: 17px;
  font-weight: 100;
  border-bottom: 3px solid #0033a0c4;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  margin-top: 3em;
  margin: 15px auto !important;
  width: 215px;
}
.front .panel.main-panel .panel-body .top .push-form a {
  color: #fff !important;
}
.front .panel.main-panel .panel-body .top .main-text {
  position: absolute;
  top: 164px;
  left: 10px;
  color: #e10749;
  font-size: 21px;
  width: 100%;
}
.front .panel.main-panel .panel-body .top .main-text .text-header {
  font-weight: 800;
  width: 940px;
  margin: 0 auto;
  text-align: center;
  background: #0033a0c4;
  padding: 7px 10px;
  color: #fff;
}
.front .panel.main-panel .panel-body .top .main-text .text-subheading {
  color: #ffffff;
  width: 940px;
  margin: 0 auto;
  text-align: center;
  font-size: 18px;
  background: #0033a0c4;
  padding: 7px 10px;
}
.front .panel.main-panel .panel-body .top .main-text p {
  margin: 0;
}
.front .panel.main-panel .panel-body .top .form-feedback {
  position: absolute;
  top: 54px;
  right: 14px;
}
.front .panel.main-panel .panel-body .bottom {
  height: 326px;
}
.front .panel.main-panel .panel-body .bottom h2 {
  font-size: 48px;
  color: #485c67;
  font-family: "Times New Roman";
  margin-top: 0;
  text-align: center;
  letter-spacing: 2.5px;
}
.front .panel.main-panel .panel-body .bottom h3 {
  color: #485c67;
  font-size: 35px;
  font-family: "Times New Roman";
  text-align: center;
  letter-spacing: 2.5px;
}
.front .panel.about-us-panel {
  text-align: center;
  background-color: #f5f5f5;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-top: 5px solid #c6c5c5;
  border-bottom: 5px solid #c6c5c5;
}
.front .panel.about-us-panel h3 {
  text-transform: uppercase;
  color: #333;
  font-family: Open Sans;
  font-weight: bold;
  background: none;
}
.front .panel.about-us-panel .panel-body {
  padding-top: 40px;
}
.front .panel.about-us-panel .panel-body .row {
  margin-bottom: 44px;
}
.front .panel.about-us-panel .panel-body .circle {
  border: 3px solid #0033a0c4;
  width: 55px;
  height: 55px;
  background: #ededed;
  border-radius: 50px;
  display: inline-block;
  position: absolute;
  left: -55px;
  top: 10px;
  text-align: center;
}
.front .panel.about-us-panel .panel-body .circle:before {
  content: "";
  display: inline-block;
  margin-top: 6px;
  transform: scale(0.7, 0.7);
  -ms-transform: scale(0.7, 0.7);
  -webkit-transform: scale(0.7, 0.7);
}
.front .panel.about-us-panel .panel-body .circle.team:before {
  background-image: url(/images/sprite.png);
  background-position: -88px -183px;
  width: 43px;
  height: 40px;
}
.front .panel.about-us-panel .panel-body .circle.quality:before {
  background-image: url(/images/sprite.png);
  background-position: 0px -227px;
  width: 36px;
  height: 35px;
}
.front .panel.about-us-panel .panel-body .circle.prices:before {
  background-image: url(/images/sprite.png);
  background-position: -237px -42px;
  width: 22px;
  height: 40px;
  width: 26px;
}
.front .panel.about-us-panel .panel-body .circle.map:before {
  background-image: url(/images/sprite.png);
  background-position: -119px -121px;
  width: 46px;
  height: 41px;
}
.front .panel.about-us-panel .panel-body .circle.time:before {
  background-image: url(/images/sprite.png);
  background-position: -237px 0px;
  width: 34px;
  height: 40px;
}
.front .panel.about-us-panel .panel-body .circle.discount:before {
  background-image: url(/images/sprite.png);
  background-position: -38px -227px;
  width: 35px;
  height: 35px;
}
.front .panel.about-us-panel .panel-body .circle.partner:before {
  background-image: url(/images/sprite.png);
  background-position: -133px -183px;
  width: 51px;
  height: 33px;
}
.front .panel.about-us-panel .panel-body h5 {
  font-family: Open Sans;
  font-size: 18px;
  line-height: 1.35;
  text-transform: uppercase;
  font-weight: bold;
  color: #565a64;
}
.front .panel.about-us-panel .panel-body p {
  font-size: 18px;
  color: #565a64;
  font-family: Open Sans;
}
.front .panel.about-us-panel .panel-body p + p {
  font-size: 16px;
}
.front .panel.services-panel {
  text-align: center;
  padding: 13px 20px;
  background: #ebebeb;
  margin-top: 56px !important;
}
.front .panel.services-panel h3 {
  color: #c02020;
  display: inline-block;
  margin: 0 0 15px 0;
  padding: 0;
  color: #F26100;
  font-family: Helvetica Neue, Arial, sans-serif;
  font-size: 26px;
}
.front .panel.services-panel img {
  width: 250px;
  float: left;
  padding-right: 20px;
}
.front .panel.services-panel .panel-body .services-list {
  list-style: none;
  text-align: left;
}
.front .panel.services-panel .panel-body .services-list li {
  list-style: none;
  font-size: 28px;
  color: #343739;
  background: #ffffff;
  border: 4px solid #c6c5c5;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 55px;
  position: relative;
}
.front .panel.services-panel .panel-body .services-list li:after {
  content: '';
  display: inline-block;
  background: #fff;
  width: 4px;
  height: 24px;
  position: absolute;
  top: 60px;
  left: 50%;
}
.front .panel.services-panel .panel-body .services-list li.spec {
  margin-bottom: 141px;
}
.front .panel.services-panel .panel-body .services-list li.spec:after {
  display: none;
}
.front .panel.services-panel .panel-body .services-list li.spec ul {
  position: absolute;
  width: 100%;
  padding: 0;
}
.front .panel.services-panel .panel-body .services-list li.spec ul li {
  display: inline-block;
  margin-top: 55px;
  padding: 0 50px;
}
.front .panel.services-panel .panel-body .services-list li.spec ul li.left {
  float: left;
  margin-left: -75px;
}
.front .panel.services-panel .panel-body .services-list li.spec ul li.right {
  float: right;
  margin-right: -75px;
}
.front .panel.services-panel .panel-body .services-list li.spec ul li:after {
  top: -40px;
}
.front .panel.benefits-panel {
  border-top: 5px solid #c6c5c5;
  border-bottom: 5px solid #c6c5c5;
  margin-top: 40px;
}
.front .panel.benefits-panel .panel-body {
  padding: 25px;
}
.front .panel.benefits-panel .panel-body .benefits-list {
  list-style: none;
}
.front .panel.benefits-panel .panel-body .benefits-list li {
  list-style: none;
  font-size: 22px;
  color: #7c8b93;
  position: relative;
  margin-bottom: 40px;
}
.front .panel.benefits-panel .panel-body .benefits-list li:before {
  content: '';
  display: inline-block;
  position: absolute;
  left: -95px;
}
.front .panel.benefits-panel .panel-body .benefits-list li.work:before {
  background-image: url(/images/sprite.png);
  background-position: -96px 0px;
  width: 75px;
  height: 55px;
}
.front .panel.benefits-panel .panel-body .benefits-list li.doc:before {
  background-image: url(/images/sprite.png);
  background-position: -173px 0px;
  width: 62px;
  height: 62px;
}
.front .panel.benefits-panel .panel-body .benefits-list li.settings:before {
  background-image: url(/images/sprite.png);
  background-position: 0px -58px;
  width: 67px;
  height: 61px;
}
.front .panel.benefits-panel .panel-body .benefits-list li.grafic:before {
  background-image: url(/images/sprite.png);
  background-position: -69px -58px;
  width: 70px;
  height: 56px;
}
.front .panel.benefits-panel .panel-body .benefits-list li.experience:before {
  background-image: url(/images/sprite.png);
  background-position: -62px -121px;
  width: 55px;
  height: 56px;
}
.front .panel.equipment-panel {
  text-align: center;
}
.front .panel.equipment-panel h3 {
  color: #4e525d;
  padding: 7px 10px;
  display: inline-block;
  margin: 50px 0 42px 0;
  font-size: 42px;
}
.front .panel.equipment-panel .description {
  color: #4e525d;
  font-size: 30px;
}
.front .panel.equipment-panel .panel-body {
  text-align: left;
}
.front .panel.equipment-panel .panel-body .col {
  margin-bottom: 60px;
  display: table;
}
.front .panel.equipment-panel .panel-body .col.mgtop {
  margin-top: 40px;
}
.front .panel.equipment-panel .panel-body img {
  float: left;
  padding-right: 30px;
}
.front .panel.equipment-panel .panel-body p {
  font-size: 20px;
  color: #4e525d;
  vertical-align: middle;
  display: table-cell;
}
.front .panel.custom-panel {
  border-top: 5px solid #c6c5c5;
  border-bottom: 5px solid #c6c5c5;
  margin-top: 40px;
}
.front .panel.requisites-panel {
  background: #c6c5c5;
  margin: 50px auto !important;
  font-size: 20px;
  color: #565a64;
}
.front .panel.requisites-panel .panel-body {
  margin: 24px 74px;
  padding: 0;
  padding-left: 40px;
  border-left: 1px solid #888888;
}
.front .panel.requisites-panel .panel-body .col {
  padding-left: 1px;
  border-right: 1px solid #888888;
}
.front .panel.requisites-panel .panel-body .col.text-right {
  text-align: right;
}
.front .panel.requisites-panel .panel-body .col.text-center {
  text-align: center;
}
.front .panel.requisites-panel .panel-body .col.last {
  padding-right: 20px;
}
.front .panel.emulators-panel {
  background: #E5E5E5;
  background-color: #f5f5f5;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding-bottom: 135px;
}
.front .panel.emulators-panel .panel-body .emulators-boxes {
  margin: 0 auto;
  text-align: center;
}
.front .panel.emulators-panel .panel-body .emulators-boxes .emulator {
  display: inline-block;
  margin-right: 35px;
  position: relative;
}
.front .panel.emulators-panel .panel-body .emulators-boxes .emulator .overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #7f8e9a;
  transition: all 500ms ease;
  top: 0;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  background: rgba(127, 142, 154, 0.5);
}
.front .panel.emulators-panel .panel-body .emulators-boxes .emulator .overlay span {
  background-image: url(/images/sprite.png);
  background-position: -173px -64px;
  width: 54px;
  height: 45px;
  height: 40px;
  width: 40px;
  display: block;
  margin: 0 auto;
  margin-top: 90px;
}
.front .panel.emulators-panel .panel-body .emulators-boxes .emulator:hover .overlay {
  display: block;
}
.front .panel.emulators-panel .panel-body .emulators-boxes .emulator:hover:before {
  position: absolute;
  border: 6px solid;
  border-color: #cdb289 rgba(255, 255, 255, 0) #cdb289 #cdb289;
  width: 52px;
  height: 235px;
  content: '';
  display: block;
  top: -6px;
  left: -6px;
}
.front .panel.emulators-panel .panel-body .emulators-boxes .emulator:hover:after {
  position: absolute;
  border: 6px solid;
  border-color: #cdb289 #cdb289 #cdb289 rgba(148, 140, 127, 0);
  width: 52px;
  height: 235px;
  content: '';
  display: block;
  top: -6px;
  right: -6px;
}
.front .panel.emulators-panel .panel-body .emulators-boxes .emulator.last {
  margin-right: 0;
}
.front .panel.equipments-panel .bg-img {
  background: url('/images/website-adblue-1.jpg');
  background-size: cover;
  min-height: 570px;
  padding: 0;
}
.front .panel.equipments-panel .layer-img {
  background: rgba(0, 0, 0, 0.6);
  padding: 8em 3em;
}
.front .panel.equipments-panel .layer-img .info {
  background: rgba(119, 119, 119, 0.3);
  padding: 4em 3em;
  border: 1px solid #888;
}
.front .panel.equipments-panel .layer-img .info img {
  position: absolute;
  top: 0;
  left: 0;
}
.front .panel.equipments-panel .layer-img .info h3 {
  font-size: 30px;
  color: #fff;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 15px;
}
.front .panel.equipments-panel .layer-img .info p {
  font-size: 17px;
  color: #eee;
  letter-spacing: 0px;
  line-height: 19px;
}
.front .panel.equipments-panel .layer-img .info .link-wrapper {
  margin-top: 10px;
}
.front .panel.equipments-panel .layer-img .info a {
  display: block;
  width: 100px;
  margin: 10px 0;
  color: #fff !important;
  padding: 8px 15px;
  border: 1px solid #fff;
  letter-spacing: 1px;
}
.front .panel.equipments-panel .layer-img .info a:hover {
  background: #0033a0c4;
  border: 1px solid #002366;
}
.front .panel.equipments-panel .grid_info p {
  font-size: 15px;
  color: #585858;
  letter-spacing: 1px;
}
.front .panel.equipments-panel .grid_info h3 {
  margin-top: 8px;
  text-transform: uppercase;
}
.front .panel.map-panel {
  padding: 0;
  border: none;
  border-top: 5px solid #c6c5c5;
}
.front .panel.map-panel .panel-body {
  padding-top: 50px;
}
.front .panel.useful-inf-panel {
  background: #c6c5c5;
  text-align: center;
  margin-top: 60px !important;
  margin-bottom: 20px !important;
}
.front .panel.useful-inf-panel h3 {
  text-align: center;
  color: #c02020;
  padding: 7px 10px;
  display: inline-block;
  margin: 52px 0 22px 0;
  font-size: 35px;
}
.front .panel.useful-inf-panel .panel-body {
  text-align: left;
  font-size: 26px;
}
.front .panel.useful-inf-panel .panel-body h2,
.front .panel.useful-inf-panel .panel-body h3,
.front .panel.useful-inf-panel .panel-body h4 {
  font-weight: bold;
}
