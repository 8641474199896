.carousel  {
  max-width: 1080px;
  margin: 0 auto 50px auto;
  width:100%;
}
.carousel-wrapper {
  margin: 10px 30px;
  overflow: hidden;
  position:relative;
}
.carousel-items {
  width: 10000px;
  position: relative;
}
.carousel-block {
  float: left;
  width: auto;
  padding: 10px 58px 10px 0px;
}
.carousel-block img{
  display:block;
  height: 80px;
}

.carousel-button-left a, .carousel-button-right a{
  width: 25px;
  height: 36px;
  position: relative;
  top: 80px;
  cursor: pointer;
  text-decoration:none;
  display: none;
}
.carousel-button-left a{
  float: left;
  .sprite(@icon-slider-prev-control);
}
.carousel-button-right a{
  float: right;
  .sprite(@icon-slider-next-control);
}

.shadow{
  //box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
  //background: #f2f2f3;
  padding: 10px 15px;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
  position: relative;
}
