@import "../sprites.less";

.form-feedback {

  .control {
    position: relative;

    input[type="text"] {
      background: none;
      border: 2px solid white;
      font-size: 16px;
      color: #fff;
      padding: 0 12px;
      text-align: center;
      height: 25px;
      //line-height: 25px;
    }

    &:before {
      position: absolute;
      left: -36px;
      top: 0;
      content: "";
      display: inline-block;
    }

    &.control-phone {
      margin-bottom: 10px;
      &:before {
        .sprite(@icon-phone);
      }
    }

    &.control-name {
      margin-bottom: 17px;
      &:before {
        left: -39px;
      }
    }
  }

  .form-actions {
    position: relative;

    input[type="submit"] {
      background: none;
      border: none;
      color: #fff;
      font-size: 24px;
    }

    .phone-icon {
      background: #c24949;
      width:35px;
      height: 35px;
      border: 2px solid #f13131;
      position: absolute;
      left: -45px;
      top: 0;
      border-radius: 10px;

     &:before {
       content: "";
       display: inline-block;
       margin: 4px;
     }
    }

  }

  .form-control::-webkit-input-placeholder {
    color: #fff;
  }
}

  #form-feedback {
    .control {
      margin-bottom: 10px;
    }

    imput[type="text"] {
      margin: 0 20px;
      border: 3px solid #cdb289;
      background: none;
      width: auto;
      font-size: 15px;
      font-family: CenturyGothic;
      color: #fff;
      outline: none;
    }

    input[type="submit"] {
      margin: 30px 20px;
      background: #85939e;
      display: block;
      width: auto;
      color: #fff;
      text-align: center;
      padding: 11px 24px;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 700;
      border: 0;
    }
  }

.feedback-modal-box {
  display: none;
  position: fixed;
  z-index: 1000;
  width: 300px;
  background: #fff;
  border-bottom: 1px solid #aaa;
  border-radius: 4px;
  box-shadow: 0 3px 9px rgba(0,0,0,.5);
  border: 1px solid rgba(0,0,0,.1);
  background-clip: padding-box;
  max-width: 300px;
  height: 186px;
  -webkit-overflow-scrolling: touch;

  .close {
    float: none;
    position: absolute;
    right: 4px;
    z-index: 666;
  }
}


.feedback-modal-overlay {
  display: none;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 900;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.3)!important;
}
