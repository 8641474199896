@import "media.less";

.b-slideshow {
  margin: 0 auto;
  position: relative;
  //overflow: hidden;
  height: 424px;
  background: #fff;
  @media @mobile {
    height: auto;
  }
  @media @tablet {
    height: 290px;
  }
  .b-slideshow-area {
    height: 424px;
    overflow: hidden;
    background: #fff;
    @media @mobile {
      min-height: 120px;
      height: auto;
    }
    @media @tablet {
      height: 290px;
    }
    .b-click {
      width: 42px;
      height: 42px;
      position: absolute;
      top: 190px;
      background: #464544;
      @media @mobile {
        top: 40px;
        opacity: .65;
        &.prev {
          left: 0 !important;
        }
        &.next {
          right: 0 !important;
        }
      }
      @media @tablet {
        top: 90px;
        opacity: .65;
        &.prev {
          left: 0 !important;
        }
        &.next {
          right: 0 !important;
        }
      }
      &.prev {
        left: -70px;
        .sprite(@icon-slider-prev-control);

      }
      &.next {
        right: -70px;
        .sprite(@icon-slider-next-control);
        z-index: 2;
      }
      &:hover {
        opacity: .8;
      }
    }
    .b-slideshow-list {
      position: relative;
      left: 0;
      letter-spacing: -0.25em;
      margin: 0px;
      padding: 0px;
      .b-slideshow-item {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        color: #ffffff;
        font-size: 3.75em;
        font-weight: 400;
        -webkit-background-size: cover;
        text-align: center;
        width: 100%;
        height: 423px;
        @media @mobile {
          //height: 303px;
          height: auto;
          position: static;
          &:not(.view) {
            display: none !important;
          }
        }
        @media @tablet {
          height: 290px;
        }
        &.view {
          display: inline-block;
        }
        img {
          width: 100%;
          max-width: 1170px;
        }
        .text {
          position: absolute;
          width: 580px;
          height: 120px;
          top: 50%;
          left: 50%;
          margin-left: -290px;
          margin-top: -60px;
          color: #fff;
          font-size: 37px;
          font-family: 'CenturyGothic';
          text-transform: uppercase;
          text-align: center;
          letter-spacing: 0;
          span {
            font-size: 47px;
            padding-bottom: 15px;
            display: block;
          }
        }
        .slide-content {
          font-size: 21px;
          color: #4e4e4e;
          text-transform: uppercase;
          letter-spacing: -1px;
          font-weight: bold;
          margin: 0;
          padding: 0 10px;
          width: 390px;
          z-index: 2;
          //font-family: 'CenturyGothic';
          font-family: Open Sans;
        }
        .slide-text {
          font-size: 15px;
          font-weight: normal;
          margin-top: 10px;
          p {
            margin-bottom: 0;
          }
        }
        .slide-icon {
          @media @mobile {
            display: block;
          }
        }
        .content-overlay {
          background: #fff;
          opacity: 0.8;
          padding: 15px 15px 15px 35px;
          position: absolute;
          left: 50%;
          text-align: left;
          margin-left: -585px;
          bottom: 20px;
          text-align: left;
          @media @mobile {
            box-sizing: border-box;
            width: 100%;
            left: 0;
            padding: 5px;
            position: static;
            h3.slide-content {
              width: 100%;
              font-size: 20px;
              padding: 0;
            }
            h5.slide-content {
              width: 100%;
              font-size: 14px;
              padding: 0;
            }
          }
          @media @tablet {
            left: 70px;
          }
        }
        .btn {
          background: #ff065e;
          color: #fff;
          padding: 10px 20px;
          vertical-align: bottom;
          margin-left: 10px;
          @media @mobile {
            margin-top: 5px;
            margin-bottom: 5px;
            padding: 2px 5px;
            vertical-align: top;
            margin-left: 0;
            display: block;
          }
        }
      }
    }
  }
}

.slide {
  .partners-icons {
    width: 500px;
    position: absolute;
    left: 473px;
    top: 10px;
    .partner-block {
      width: 60px;
      height: 60px;
      float: left;
      margin-right: 10px;
    }
  }
  .phones {
    position: absolute;
    right: 30px;
    top: 20px;
    width: 186px;
    font-size: 13px;
    letter-spacing: initial;
    //font-family: 'CenturyGothic';
    font-family: Open Sans;
    color: #4e4e4e;
    p {
        font-size: 14px;
        margin: 0;
    }
  }
  .push-form {
    border: none;
    font-family: 'Questrial', sans-serif;
    color: #fff;
    background: #0033a0c4;
    cursor: pointer;
    padding: 4px 14px;
    letter-spacing: 1px;
    outline: none;
    font-size: 13px;
    font-weight: 100;
    border-bottom: 3px solid #0033a0c4;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -o-border-radius: 4px;
    margin-top: 3em;
    margin: 10px 11px;
    width: 156px;
    &:hover {
      background: #002366;
      border-bottom: 3px solid #021233c4;
    }
  }
}
